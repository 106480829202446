.opacity-0,
.opacity-0--hover:hover,
.opacity-container:hover .opacity-0--hover {
    opacity: 0;
}
.opacity-1,
.opacity-1--hover:hover,
.opacity-container:hover .opacity-1--hover {
    opacity: 0.1;
}
.opacity-2,
.opacity-2--hover:hover,
.opacity-container:hover .opacity-2--hover {
    opacity: 0.2;
}
.opacity-3,
.opacity-3--hover:hover,
.opacity-container:hover .opacity-3--hover {
    opacity: 0.3;
}
.opacity-4,
.opacity-4--hover:hover,
.opacity-container:hover .opacity-4--hover {
    opacity: 0.4;
}
.opacity-5,
.opacity-5--hover:hover,
.opacity-container:hover .opacity-5--hover {
    opacity: 0.5;
}
.opacity-6,
.opacity-6--hover:hover,
.opacity-container:hover .opacity-6--hover {
    opacity: 0.6;
}
.opacity-7,
.opacity-7--hover:hover,
.opacity-container:hover .opacity-7--hover {
    opacity: 0.7;
}
.opacity-8,
.opacity-8--hover:hover,
.opacity-container:hover .opacity-8--hover {
    opacity: 0.8;
}
.opacity-9,
.opacity-9--hover:hover,
.opacity-container:hover .opacity-9--hover {
    opacity: 0.9;
}
.opacity-10,
.opacity-10--hover:hover,
.opacity-container:hover .opacity-10--hover {
    opacity: 1;
}