.input-group-text {
    transition: $input-transition;
}

.input-group-transparent {
    .input-group-text {
        color: $input-group-addon-color;
        background-color: transparent;
        border: $input-border-width solid $input-group-addon-border-color;
    }

    .form-control {
        box-shadow: none;

        &:not(:first-child) {
            border-left: 0;
            padding-left: 0;
        }

        &:not(:last-child) {
            border-right: 0;
            padding-right: 0;
        }
    }
}

.focused {
    // Add shadow on the input group (not on .form-control)
    .input-group {
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }

        .form-control {
            box-shadow: none !important;
        }
    }

    // Additional styles for the input addon element
    .input-group-text {
        color: $input-group-addon-focus-color;
        background-color: $input-group-addon-focus-bg;
        border-color: $input-group-addon-focus-border-color;
    }

    .input-group-transparent {
        .input-group-text {
            background-color: $input-focus-bg;
            border-color: $input-group-addon-focus-border-color;
        }
    }
}
