// Copy and paste the variables that you want to modify from _variables.scss and remove the !default flad. 
// The default ones will be overridden. Below we added the most wanted variables that a user needs to change.
// Uncomment the variables starting with "$" that you want to edit.


// Colors

$primary: #021c34;
$secondary: #29a814;
//$spacer: .5rem !default;

$section-colors: (
  "primary": transparent
);

// $primary: #f00;
// $secondary: #234242;

// Typography

// $font-family-base: 'Nunito', sans-serif !default;
// $font-family-alt: 'Nunito', sans-serif !default;
