.progress-wrapper {
    position: relative;
    padding-top: 1.5rem;
}
.progress {
    height: 1rem;
    margin-bottom: $spacer;
    overflow: hidden;
    border-radius: $border-radius-sm;
    background-color: $progress-bg;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-inverse {
    background-color: inverse($progress-bg);
}

.progress-heading {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 2px;
    padding: 0;
}

.progress-bar {
    box-shadow: none;
    border-radius: 0;
    height: auto;
}

.progress-text {
    margin-bottom: 0;
}

.progress-tooltip {
    display: inline-block;
    background: rgba(0, 0, 0, .8);
    color: $white;
    padding: .25rem .375rem;
    line-height: 1;
    font-size: 0.7rem;
    position: relative;
    bottom: 8px;
    border-radius: 3px;
    margin-left: -15px;

    &:after {
        top: 100%;
        left: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(51, 51, 51, 0);
        border-top-color: rgba(0, 0, 0, .8);
        border-width: 5px;
    }
}

.progress-label {
    display: inline-block;
    color: $gray-600;
    position: absolute;
    top: 0;
    left: 0;
    font-size: $font-size-xs;
}

.progress-percentage {
    display: inline-block;
    color: $gray-600;
    font-size: 1rem;
    position: absolute;
    right: 0;
    top: 0;
}


// Progress sizes

.progress-lg {
    height: 1.5rem;
}
.progress-md {
    height: 1rem;
}
.progress-sm {
    height: .625rem;
}
.progress-xs {
    height: .25rem;
}


// Progress prepend icons

.progress-group {
    position: relative
}

.progress-prepend-icon {
    position: absolute;
    transform: translateY(-50%);
    font-size: 20px;
    top: 50%;

    &:not(:first-child) {
        right: -17px;
    }
    &:not(:last-child) {
        left: -17px;
    }
}
