.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.mask + .container {
	position: relative;
}


// Animated mask transparency

.card {
	.mask {
		border-radius: $card-border-radius;
	}

	.mask--hover {
		opacity: 0;
		-webkit-transition-delay: 250ms; 
  		transition-delay: 250ms; 
	}

	&:hover {
		.mask--hover {
			opacity: .4;
		}
	}
}
