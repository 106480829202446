.card {
    position: relative;
}

// Transulcent cards

.card-translucent-dark {
    background: rgba(255, 255, 255, .2);
}

.card-translucent-light {
    background: rgba(0, 0, 0, .2);
}

// Images as card backgrounds

.card-img-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    border-radius: $card-border-radius;
    -webkit-backface-visibility: hidden;
    transition: all .7s cubic-bezier(.2,1,.22,1);
}

.card:hover .card-img-bg {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

.card-img-bg ~ .card-body {
    position: relative;
}


// Card columns

[data-lg-columns="2"] {
    column-count: 2 !important;
}

[data-lg-columns="3"] {
    @include media-breakpoint-only(lg) {
        column-count: 3 !important;
    }
}

[data-lg-columns="4"] {
    @include media-breakpoint-only(lg) {
        column-count: 4 !important;
    }
}


// Pricing

.card-pricing {
    .card-header {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    .list-unstyled li {
        padding: .5rem 0;
        color: $gray-600;
    }
}

.card-pricing.popular {
    z-index: 1;
    border: 3px solid theme-color("primary") !important;
}

@include media-breakpoint-up(md) {
    .card-pricing.zoom-in {
        z-index: 1;
        transform: scale(1.1);
    }
}

// Card comment box

.card-comment-box {
    input,
    textarea {
        width: 100%;
        border: 0;
        box-shadow: none;
        background: transparent;
        padding: .3125rem 0;
        margin: 0;
        color: $gray-800;
        outline: none;
        resize: none;

        &:focus {
            background: transparent;
            box-shadow: none;
        }
    }
}


// Profile card

.card-profile {
    .card-profile-image {
        width: 7rem;
        height: 7rem;
        border: .1875rem solid $card-bg;
        transform: translateY(-50%);
    }

    .card-body {
        position: relative;
        z-index: 2;
        margin-top: -3.5rem;
    }
}

.card-profile-cover {
    max-height: 300px;
    overflow: hidden;
}

// Product card

.card-product {
    overflow: hidden;

    .card-image {
        padding: 1.5rem;

        img {
            transform: scale(1);
            @include transition($transition-base);
            transition-delay: .2s;
        }
    }

    &:hover {
        .card-image {
            img {
                transform: scale(1.1);
            }
        }
    }
    .card-title {
        font-size: $h6-font-size;
        color: $gray-800;
        font-weight: $font-weight-bold;
    }

    .card-price {
        color: $gray-500;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        display: block;
    }

    .product-colors {
        a {
            display: inline-block;
            float: none !important;
            width: 1rem;
            height: 1rem;
            margin-right: 3px;
            margin-bottom: 0;
            border-radius: 50%;
            @include transition($transition-base);

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

/* Product actions */
.card-product-actions {
    opacity: 0;
    width: 80px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
    background: transparent;

    &.in {
        opacity: 1;
    }

    &.animated {
        animation-duration: .5s;
    }

    .btn-product-action {
        display: inline-block;
        float: left;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 0;
        background: transparent;
        color: $gray-800;
        outline: 0;
        cursor: pointer;

        &:hover {
            background: transparent;
            color: theme-color("primary");
        }

        &:active {
            border-color: transparent;
        }
    }
}

// Card group
.card-group {
    .card .mask {
        border-radius: 0;
    }
}
