.header-account-page {
	@include media-breakpoint-up(md) {
		height: 350px;
	}
	
	@include media-breakpoint-down(md) {
		padding-top: 9rem;
	}

	.btn-group-nav {
		position: relative;
		z-index: 1;
		transform: translateY(50%);
	}
}