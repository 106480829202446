// SVG shapes used as separators or illustrations

.shape-container {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    z-index: 2;
    transform: translateZ(0);
    overflow: hidden;

    &[data-shape-position="top"] {
        top: 0;
        bottom: auto;
    }

    &[data-shape-position="bottom"] {
        top: auto;
        bottom: 0;
    }

    &[data-shape-orientation="inverse"] {
        transform: rotate(180deg);
    }
}

// Fill, stroke and other svg elements

@each $color, $value in $colors {
    .shape-fill-#{$color}  {
        fill: $value !important;
    }
    .shape-stroke-#{$color}  {
        stroke: $value !important;
    }
}

// Shape styles

[data-shape-style="curve"] {
    height: 80px;
}

[data-shape-style="wave"] {
    height: 80px;
}

[data-shape-style="wavify"] {
    height: 120px;
}

@media (min-width: 2400px) {
    [data-shape-style="curve"] {
        height: 150px;
    }
}

@media (min-width: 1200px) and (max-width: 2399px) {
    [data-shape-style="curve"] {
        height: 100px;
    }
}

@include media-breakpoint-down(lg) {
    [data-shape-style="curve"] {
        height: 60px;
    }

    [data-shape-style="wave"] {
        height: 40px;
    }
}

@include media-breakpoint-down(md) {
    [data-shape-style="curve"] {
        height: 40px;
    }
}
