// General

.btn {
    position: relative;

    .badge {
        position: absolute;
        top: -.625rem;
        right: -.3125rem;
    }
}


// Fixes

.btn-outline-secondary {
    color: darken(theme-color("secondary"), 50%);
}

.input-group .btn,
.input-group .btn:focus,
.btn-group .btn,
.btn-group .btn:focus {
    box-shadow: none;
}

// Sizes

.btn-xl {
    @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $font-size-xl, $btn-line-height-xl, $btn-border-radius-xl);
}


// Icons

.btn svg:not(:first-child),
.btn i:not(:first-child) {
    margin-left: .5rem;
}

.btn svg:not(:last-child),
.btn i:not(:last-child) {
    margin-right: .5rem;
}


// Animated

.btn-animated {
    position: relative;
    overflow: hidden;

    .btn-inner--visible {
        position: relative;
    }
    .btn-inner--hidden {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.btn-animated-x {
    .btn-inner--visible {
        right: 0;
        -webkit-transition: right .3s ease 0s;
        transition: right .3s ease 0s;
    }
    .btn-inner--hidden {
        right: -100%;
        -webkit-transition: right .3s ease 0s;
        transition: right .3s ease 0s;
    }

    &:hover .btn-inner--hidden {
        right: 0;
    }

    &:hover .btn-inner--visible {
        right: 150%;
    }
}

.btn-animated-y {
    .btn-inner--visible {
        top: 0;
        transition: top .3s ease 0s;
    }
    .btn-inner--hidden {
        left: 0;
        bottom: -150%;
        transition: bottom .3s ease 0s;
    }

    &:hover .btn-inner--hidden {
        bottom: 0;
    }

    &:hover .btn-inner--visible {
        top: 100px;
    }
}


// Labeled

.btn-action-label {
    .btn-label:hover {
        background: transparent;
        color: $gray-800;
    }
    .btn-secondary:hover {
        color: theme-color("primary");
    }
}


// Icon labels

.btn-icon-label {
    position: relative;

    .btn-inner--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        line-height: 1;
        border-radius: 0;
        text-align: center;
        margin: 0;
        width: 3em;
        background-color: rgba(0, 0, 0, .1);
    }
    .btn-inner--icon:not(:first-child) {
        right: 0;
        top: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }
    .btn-inner--icon:not(:last-child) {
        left: 0;
        top: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }
    .btn-inner--icon svg {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .btn-inner--text:not(:first-child) {
        padding-left: 3em;
    }
    .btn-inner--text:not(:last-child) {
        padding-right: 3em;
    }
}


// Icons

.btn-icon {
    .btn-inner--text:not(:first-child) {
        margin-left: 0.75em;
    }
    .btn-inner--text:not(:last-child) {
        margin-right: 0.75em;
    }
}

.btn-icon-only {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    padding: 0;
    //color: $gray-600;

    &:hover,
    &:focus {
        box-shadow: none;
    }
}

.btn-icon-only.btn-sm {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
}


// Transparent buttons (remove horizontal paddings)

.btn-nobg {
    padding: 0;
    background: transparent !important;

    &:hover {
        background: transparent !important;
        border-color: transparent !important;
    }
}

.btn-nobg--hover:hover {
    background-color: transparent;
}

// Brand buttons

@each $color, $value in $brand-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

// Hover animations

.btn-zoom--hover {
    transform: scale(1);
    @if $enable-transitions {
        transition: $transition-base;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.btn-translate--hover {
    @if $enable-transitions {
        transition: $transition-base;
    }
    &:hover {
        transform: translateY(-3px);

    }
}

// Circle buttons

.btn-circle {
    border-radius: 30px;
}


// App store download buttons

.btn-app-store {
    background: black;
    color: white;
    position: relative;
    padding-left: 4.5rem;
    padding-right: 1.5rem;

    i, svg {
        font-size: 2.5rem;
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translateY(-50%)
    }

    .btn-inner--text {
        display: block;
        font-size: .75rem;
        line-height: .75rem;
        margin-bottom: .125rem;
    }

    .btn-inner--brand {
        display: block;
        text-align: left;
        font-size: 21px;
        line-height: 21px;
    }

    &:hover {
        background-color: darken(theme-color("primary"), 7%);
        color: color-yiq(theme-color("primary"));
    }
}


// Actions

.card {
    > .actions {
        position: absolute;
        right: .875rem;
        top: 1rem;
    }
}

.card-stats {
    .actions {
        right: .75rem;
        top: .5rem;
    }
}

.action-item {
    font-size: $font-size-sm;
    cursor: pointer;
    transition: color .3s;
    color: $gray-500;
    padding: .375rem .25rem;

    &:hover {
        color: $gray-600;
    }
}

.actions-dark {
    .action-item {
        color: rgba(255, 255, 255, .7);

        &:hover {
            color: rgba(255, 255, 255, .9);
        }
    }
}
