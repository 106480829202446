/* Form controls */

.form-control { 
    &:focus {
        &::placeholder {
            color: $input-focus-placeholder-color;
        }
    }

    &.border-0,
    &.border-0:focus {
        box-shadow: none;
    }
}

/* Labels */
.form-control-label {
    color: $gray-800;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
}

/* Textareas */

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}


/* Form control sizing */

.form-control-xl {
    padding: $input-padding-y-xl $input-padding-x-xl;
    font-size: $font-size-xl;
    line-height: $input-line-height-xl;
    @include border-radius($input-border-radius-xl);
}

/* Alternative styles */

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;

    &:focus {
        background-color: $input-muted-bg;
    }
} 
 
.form-control-emphasized {
    background-color: $input-emphasized-bg;
    border-color: $input-emphasized-bg;
    box-shadow: $input-emphasized-box-shadow;

    &:focus {
        background-color: $input-emphasized-focus-bg;
        border-color: $input-emphasized-focus-border-color;
        box-shadow: $input-emphasized-focus-box-shadow;
    }
}


/* Floating labels */

.has-floating-label {
    position: relative;
}

.has-floating-label .control-label {
    font-size: $font-size-base;
    pointer-events: none;
    position: absolute;
    top: 50%;
    padding: 0 ($input-padding-x / 2);
    margin: 0 ($input-padding-x / 2);
    color: $input-placeholder-color;
    transform: translateY(-50%) scale(1);
    transform-origin: left top;
    transition: 0.3s;
    z-index: 1;
}

.has-floating-label .input-group .control-label {
    left: 2rem;
}

.has-floating-label.focused .control-label {
    padding: 0;
    margin: 0;
    top: -1.25rem;
    left: 0;
    transform: translateY(0) scale(0.85);
}


.has-floating-label .control-label-xl {
    font-size: $font-size-xl;
    padding: 0 ($input-padding-x-xl / 2);
    margin: 0 ($input-padding-x-xl / 2);
}

.has-floating-label .control-label-lg {
    font-size: $font-size-lg;
    padding: 0 ($input-padding-x-lg / 2);
    margin: 0 ($input-padding-x-lg / 2);
}

.has-floating-label .control-label-sm {
    font-size: $font-size-sm;
    padding: 0 ($input-padding-x-sm / 2);
    margin: 0 ($input-padding-x-sm / 2);
}


// Alternative colors
.form-primary {
    .form-control {
        color: color-yiq(theme-color("primary"));
        background-color: darken(theme-color("primary"), 12%);
        border: $input-border-width solid darken(theme-color("primary"), 12%);

        &:focus {
            background-color: darken(theme-color("primary"), 16%);
            border: $input-border-width solid darken(theme-color("primary"), 16%);
        }

        // Placeholder
        &::placeholder {
            color: transparentize(color-yiq(theme-color("primary")), .2);
        }
    }
}

.form-danger {
    .form-control {
        color: color-yiq(theme-color("danger"));
        background-color: darken(theme-color("danger"), 12%);
        border: $input-border-width solid darken(theme-color("danger"), 12%);

        &:focus {
            background-color: darken(theme-color("danger"), 16%);
            border: $input-border-width solid darken(theme-color("danger"), 16%);
        }

        // Placeholder
        &::placeholder {
            color: transparentize(color-yiq(theme-color("danger")), .2);
        }
    }
}

// Form styles
.form-stacked {
    .form-control {
        position: relative;
        box-sizing: border-box;

        &:first-of-type {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-of-type):not(:last-of-type) {
            margin-bottom: -1px;
            border-radius: 0;
        }

        &:last-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &:focus {
            z-index: 2;
        }
    }
}
