.badge {
    margin: .25rem 0;
}

.badge-inline {
    margin-right: .625rem;
}

.badge-inline {
    + span {
        top: 2px;
        position: relative;

        > a {
            text-decoration: underline;
        }
    }
}

.badge-md {
    padding: .65em 1em;
}

.badge-lg {
    padding: .85em 1.375em;
}

.badge-pill {
    min-width: $badge-min-width;
}

// Dot badges

.badge-dot {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    color: $gray-600;
    font-size: $font-size-sm;

    strong {
        color: $gray-800;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        width: .375rem;
        height: .375rem;
        border-radius: 50%;
        margin-right: .375rem;
    }

    &.badge-md {
        i {
            width: .5rem;
            height: .5rem;
        }
    }

    &.badge-lg {
        i {
            width: .625rem;
            height: .625rem;
        }
    }
}
