.scrollbar-inner {
    height: 100%;

    &:not(:hover) .scroll-element {
        opacity: 0;
    }

    .scroll-element {
        transition: opacity 300ms;
        margin-right: 2px;

        .scroll-bar,
        .scroll-element_track {
            transition: background-color 300ms;
        }

        .scroll-element_track {
            background-color: transparent;
        }
    }

    .scroll-element.scroll-y {
        width: 3px;
        right: 0;
    }

    .scroll-element.scroll-x {
        height: 3px;
        bottom: 0;
    }
}